<template>
  <layout-auth>
    <div class="px-138">
      <div class="my-96">
        <img src="@/assets/images/logo/dashboard-logo.svg" alt="">
      </div>
      <app-heading-1 class="">Welcome Back</app-heading-1>
      <app-normal-text class="mb-2">Please sign in to your account</app-normal-text>
      <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
      <validation-observer ref="loginForm" @keyup.enter="signIn()">
        <div class="row">
          <div class="col-12">
            <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
              <app-text-input label="Email" v-model="user_data.email" placeholder="jhon.doe@gmail.com" :errors="errors"/>
              <!-- <small class="text-danger" v-if="submitted">{{ errors[0] }}</small>  -->
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
              <app-password-input label="Password" v-model="user_data.password" placeholder="Enter password" :errors="errors"></app-password-input>
              <!-- <small class="text-danger" v-if="submitted">{{ errors[0] }}</small> -->
            </validation-provider>
          </div>
        </div>
        <div class="row my-1">
          <div class="col-12 d-flex justify-content-between">
            <b-form-group >
              <b-form-checkbox
                v-model="user_data.rememberMe"
                name="checkbox-1"
                class="d-flex align-items-center"
              >
              <app-normal-text>
                Remember Me
              </app-normal-text>
              </b-form-checkbox>
            </b-form-group>
            <b-link to="/admin/forget-password">Forget Password?</b-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <app-simple-button variant="primary" :loading="loading" class="mb-16" @click="signIn">Sign in</app-simple-button>
          </div>
        </div>
      </validation-observer>
    </div>
  </layout-auth>
</template>

<script>
import LayoutAuth from '@/layouts/layout-auth.vue'
import {
  BLink,BFormCheckbox, BFormGroup, BAlert
} from 'bootstrap-vue'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {admin_login} from '@/apis/admin/auth'
export default {
  components: {
    LayoutAuth,
    BLink,
    BAlert,
    BFormCheckbox,
    BFormGroup,
    AppHeading1,
    AppNormalText,
    AppTextInput,
    AppPasswordInput,
    AppSimpleButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      options: [
        { value: null, text: 'Please select your role' },
        { value: 'client', text: 'Client' },
        { value: 'vendor', text: 'Vendor' },
        { value: 'service_provider', text: 'Service Provider' },
        { value: 'broker', text: 'Broker'},
      ],
      user_data:{
        email:'',
        password:'',
        rememberMe: false
      },
      submitted:false,
      loading:false,
      errorMessage:'',

      // validations 
      
      required,
      email
    }
  },
  methods: {
    signIn() {
      this.errorMessage = '';
      this.$refs.loginForm.validate().then(success=>{
        if(!success){
          this.errorMessage='Please fill all the required fields & enter valid data'
          return
        }
        this.loading=true
        admin_login(this.user_data).then(resp=>{
          this.loading=true
          this.$router.push({name: 'admin-otp-verification', query:{email:this.user_data.email}})
        }).catch(e=>{
          this.loading=false
          this.errorMessage=e.message
        })
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>